import ArgonBox from "components/ArgonBox";
import Grid from "@mui/material/Grid";
import {ManagementServices} from "../../../api/BackendApi/ManagementServices";
import ArgonTypography from "../../../components/ArgonTypography";
import ArgonButton from "../../../components/ArgonButton";
import {enqueueSnackbar} from "notistack";

function DeleteNamespace({closeView, namespace}) {

    const handleDeleteNamespace = async () => {
        try {
            let response_edit = await ManagementServices.delete_namespace(namespace['namespace']);
            if (response_edit.data.deleted) {
                enqueueSnackbar("Namespace deleted successfully. Services will be deleted", {variant: "success"});
            } else {
                enqueueSnackbar("Namespace not deleted", {variant: "error"});
            }
            closeView();
        } catch (e) {
            enqueueSnackbar("Error!", {variant: "error"});
            closeView();
        }

        closeView();
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ArgonBox>
                    <ArgonTypography textAlign={"center"} fontSize={25} sx={{ fontWeight : "bold" }} mb={1} mt={2}>Are you sure you want to delete this project?</ArgonTypography>
                    <Grid container>
                        <Grid item xs={5} m={3}>
                            <ArgonButton color="dark" size="large" fullWidth onClick={closeView}>Back</ArgonButton>
                        </Grid>
                        <Grid item xs={5} m={3}>
                            <ArgonButton color="error" size="large" fullWidth onClick={handleDeleteNamespace} >Yes, Delete</ArgonButton>
                        </Grid>
                    </Grid>
                </ArgonBox>
            </Grid>
        </Grid>
    );
}


export default DeleteNamespace;
