import {useEffect, useRef, useState} from "react";
import ArgonBox from "../../../components/ArgonBox";
import Table from "../../../components/Table";
import ArgonButton from "../../../components/ArgonButton";
import ArgonBadgeDot from "../../../components/ArgonBadgeDot";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import {ManagementServices} from "../../../api/BackendApi/ManagementServices";
import {CircularProgress, tooltipClasses} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import {styled} from "@mui/material/styles";
import {useNavigate} from "react-router-dom";
import bgImage from "../../../assets/images/reply/reply-test-automation.png";

function TableNamespace({getNamespaceInfo, setOpenedEditServices, setOpenedDeleteNamespace}) {

  const navigate = useNavigate();
  const mounted = useRef(false);
  const [allNamespaces, setAllNamespaces] = useState(null);

  useEffect(() => {
    async function loadData() {
      mounted.current = true;
      await updateLoop();
    }
    loadData().then(r => console.log("Information loaded"));
  }, []);

  const updateLoop = async () => {
    try {
      if (!mounted.current) return;
        let response_services = await ManagementServices.show_all_namespace();
        setAllNamespaces(response_services.data);
    } finally {
      setTimeout(updateLoop, 15000);
    }
  }

    const goToServices = (namespace) => {
        console.log(namespace)
        navigate('/services', { state: { "namespace": namespace } });
    }

    const handleOpenDeleteDialog = (namespace) => {
        getNamespaceInfo(namespace)
        setOpenedDeleteNamespace(true)
    }

    const handleOpenEditDialog = (namespace) => {
        getNamespaceInfo(namespace)
        setOpenedEditServices(true)
    }

  const NoMaxWidthTooltip = styled(({ className, ...props }) => (
      <Tooltip {...props} classes={{ popper: className }} />
  ))({
    [`& .${tooltipClasses.tooltip}`]: {
      maxWidth: 'none',
    },
  });

  let body = <Grid container justifyContent="center" sx={{marginTop: 20, marginBottom: 20}}>
                          <CircularProgress></CircularProgress>
                      </Grid>

  if(allNamespaces != null){
    body = (
        <ArgonBox>
            <Table
                columns={[
                    { name: "customer", align: "center" },
                    { name: "status", align: "center" },
                    { name: "creation_date", align: "center" },
                    { name: "services", align: "center" },
                ]}
                rows={allNamespaces.namespaces.map((namespace) => {
                    return {
                        customer: [bgImage, namespace.name],
                        status: namespace.status === "Active" ? (
                            <ArgonBox ml={-1.325}>
                                <ArgonBadgeDot size="lg" color="success" badgeContent={namespace.status} />
                            </ArgonBox>
                        ) : (
                            <ArgonBox ml={-1.325}>
                                <ArgonBadgeDot size="lg" color="error" badgeContent={namespace.status} />
                            </ArgonBox>
                        ),
                        creation_date: namespace.creation_timestamp,
                        services: (<Grid container spacing={1}>
                            <Grid item xs={namespace.name !== "retail" && namespace.name !== "tafmanagement" ? 4 : 6} textAlign={"start"}>
                                <NoMaxWidthTooltip  title="Go to Services" placement="top">
                                    <ArgonButton variant="gradient" color="info" iconOnly
                                                 key={namespace.name}
                                                 onClick={() =>  goToServices(namespace.name)
                                                 }>
                                        <Icon>arrow_forward</Icon>
                                    </ArgonButton>
                                </NoMaxWidthTooltip>
                            </Grid>
                            {namespace.name !== "tafmanagement" && (
                                <Grid item xs={namespace.name !== "retail" && namespace.name !== "tafmanagement" ? 4 : 6} textAlign={"start"}>
                                    <NoMaxWidthTooltip  title="Settings" placement="top">
                                        <ArgonButton variant="gradient" color="info" iconOnly
                                                     key={namespace.name}
                                                     onClick={() =>  handleOpenEditDialog(namespace.name)
                                                     }>
                                            <Icon>settings</Icon>
                                        </ArgonButton>
                                    </NoMaxWidthTooltip>
                                </Grid>
                            )}
                            {namespace.name !== "retail" && namespace.name !== "tafmanagement" && (
                                <Grid item xs={4} textAlign={"start"}>
                                    <NoMaxWidthTooltip  title="Delete" placement="top">
                                        <ArgonButton variant="gradient" color="error" iconOnly
                                                     key={namespace.name}
                                                     onClick={() =>  handleOpenDeleteDialog(namespace.name)
                                                     }>
                                            <Icon>delete_forever</Icon>
                                        </ArgonButton>
                                    </NoMaxWidthTooltip>
                                </Grid>
                            )}
                        </Grid>),
                    }
                })}
            />
        </ArgonBox>
    );
  }

  return body

}

export default TableNamespace;
