import ArgonBox from "components/ArgonBox";
import {CircularProgress, FormControlLabel, FormGroup} from "@mui/material";
import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import {ManagementServices} from "../../../api/BackendApi/ManagementServices";
import ArgonTypography from "../../../components/ArgonTypography";
import Checkbox from "@mui/material/Checkbox";
import ArgonButton from "../../../components/ArgonButton";
import {enqueueSnackbar} from "notistack";
import SaveIcon from "@mui/icons-material/Save";

function EditService({closeView, namespace}) {

    const [allServices, setAllServices] = useState(null);
    const [allServicesEdited, setAllServicesEdited] = useState([]);
    const [saving, setSaving] = useState(false);

    useEffect(() => {
        async function loadData() {
            let response_services = await ManagementServices.show_all_services(namespace['namespace']);
            setAllServices(response_services.data.services);
            setAllServicesEdited(response_services.data.services?.map(
                element => {
                    return {service_name: element.service_name, type: "added"}
                })
            );
        }
        loadData().then(r => console.log("Information loaded"));
    }, []);

    const handleSaveEditServices = async () => {
        try{
            setSaving(true);
            let response_edit = await ManagementServices.save_edit_services(namespace['namespace'], allServicesEdited);
            if (response_edit.data.upgraded) {
                enqueueSnackbar("Services edited successfully. Services will be redeployed", {variant: "success"});
            } else {
                enqueueSnackbar("Services not edited", {variant: "error"});
            }
            setSaving(false);
            closeView();
        }catch(e){
            enqueueSnackbar("Error!", {variant: "error"});
            setSaving(false);
            closeView();
        }
    }

    const handleCheckboxEdit = (service_name,value) => {
        if(value){
            if(allServicesEdited.find((element) => {return element.service_name.includes(service_name)})){
                setAllServicesEdited([...allServicesEdited.filter((element) => {
                    return element.service_name !== service_name
                })]);
            }else{
                setAllServicesEdited([...allServicesEdited, {service_name: service_name, type: "added"}]);
            }
        }else{
            if(allServicesEdited.find((element) => {return element.service_name === service_name})){
                setAllServicesEdited([...allServicesEdited.filter((element) => {
                    return element.service_name !== service_name
                })]);
            }
        }
    }

    let body = <ArgonBox mt={5} mb={3}
                         style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
        <CircularProgress size={64} />
    </ArgonBox>

    if (allServices != null) {
        body = (
            <Grid container>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item xs={8}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("taf-fe-service") || element.service_name.includes("taf-be-service")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("taf-fe-service", e.target.checked)
                                                  }} label="TAF" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("taf-orchestrator-service")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("taf-orchestrator-service", e.target.checked)
                                                  }} label="Orchestrator" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("taf-node-service")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("taf-node-service", e.target.checked)
                                                  }} label="Node" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("taf-chatbot-service")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("taf-chatbot-service", e.target.checked)
                                                  }} label="Taffy" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={6}>
                    <Grid container>
                        <Grid item xs={2}>
                        </Grid>
                        <Grid item xs={10}>
                            <FormGroup>
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("taf-map-service")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("taf-map-service", e.target.checked)
                                                  }} label="Application Explorer" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("taf-app-monitoring-service")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("taf-app-monitoring-service", e.target.checked)
                                                  }} label="Application Monitoring" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("taf-generative-service")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("taf-generative-service", e.target.checked)
                                                  }} label="Generative Test" />
                                <FormControlLabel control={<Checkbox defaultChecked={allServices.find((element) => {return element.service_name.includes("taf-integrationmanagement-service")})} />}
                                                  onChange={(e) => {
                                                      handleCheckboxEdit("taf-integrationmanagement-service", e.target.checked)
                                                  }} label="Integration Mngmt Tool" />
                            </FormGroup>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={5} m={3}>
                    <ArgonButton color="dark" size="large" fullWidth onClick={closeView}>Back</ArgonButton>
                </Grid>
                <Grid item xs={5} m={3}>
                    <ArgonButton color="info" size="large" fullWidth onClick={handleSaveEditServices} disabled={allServicesEdited.length === 0}>
                        {saving ? <CircularProgress color="white" size={14} /> : <SaveIcon />}
                        &nbsp; {saving ? "Saving..." : "Save"}
                    </ArgonButton>
                </Grid>
            </Grid>
        );
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <ArgonBox>
                    <ArgonTypography textAlign={"center"} fontSize={25} sx={{ fontWeight : "bold" }} mb={1} mt={2}>Services</ArgonTypography>
                    {body}
                </ArgonBox>
            </Grid>
        </Grid>
    );
}


export default EditService;
