import apiClient from "api/BackendApi";

const ManagementServices = {
    show_all_namespace: () =>
        apiClient.get("/be/managementServices/show_all_namespace"),

    show_all_services: (service) =>
        apiClient.post("/be/managementServices/show_all_services",{"service": service}),

    redeploy_service: (namespace, service) =>
        apiClient.post("/be/managementServices/redeploy_service", {"namespace": namespace, "service": service}),

    kill_pod: (namespace, service, pod) =>
        apiClient.post("/be/managementServices/kill_pod", {"namespace": namespace, "service": service, "pod": pod}),

    get_pods: (namespace, service) =>
        apiClient.post("/be/managementServices/get_pods", {"namespace": namespace, "service": service}),

    get_pod_details: (namespace, service) =>
        apiClient.post("/be/managementServices/get_pod_details", {"namespace": namespace, "service": service}),

    get_pod_log: (namespace, pod) =>
        apiClient.post("/be/managementServices/get_pod_logs", {"namespace": namespace, "pod": pod}),

    get_cluster_info: (namespace, service) =>
    apiClient.post("/be/managementServices/get_cluster_info", {"namespace": namespace, "service": service}),

    save_edit_services: (namespace, serviceEdited) =>
        apiClient.post("/be/managementServices/upgrade", {"namespace": namespace, "serviceEdited": serviceEdited}),

    delete_namespace: (namespace) =>
        apiClient.post("/be/managementServices/deleteNamespace", {"namespace": namespace}),
}

export {
    ManagementServices
};
